
.headers[data-v-5c61ba1e] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-5c61ba1e] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-5c61ba1e] {
  margin-left: 0px;
}
.paging[data-v-5c61ba1e] {
  float: right;
  margin-top: 10px;
}
.show_table[data-v-5c61ba1e] {
  position: relative;
  width: 100%;
  height: 88vh;
  overflow: auto;
}
